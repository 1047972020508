import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBhiWE68frF9hlkbmcTAQ5p4ycidm6xSWQ",
  authDomain: "money-cart-326ac.firebaseapp.com",
  projectId: "money-cart-326ac",
  storageBucket: "money-cart-326ac.appspot.com",
  messagingSenderId: "758043186313",
  appId: "1:758043186313:web:ad7a2392e9a46801ede258",
  measurementId: "G-9CCJ5KWSKN"
};

initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
    <ToastContainer />
    </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
