import React, { useState, useEffect, useMemo } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../firebase'; 
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, TextField, Button, CircularProgress } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';

const firestore = getFirestore(app);

const TdsCredentials = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch users
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch additional data
        const incomeSiteRegistrationSnapshot = await getDocs(collection(firestore, 'incomesite_registration'));
        const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
        const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));

        const incomeSiteData = incomeSiteRegistrationSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() }));
        const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() ,  timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
          }));
        const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Merge usersData with additional data
        const mergedData = usersData.map(user => {
          const incomeSite = incomeSiteData.find(inc => inc.userId === user.id) || {};
          const tdsForm = tdsFormData.find(tdsp => tdsp.userId === user.id) || {};
          const tdsState = tdsStateData.find(tds => tds.id === user.id) || {};
          return { ...user, ...incomeSite, ...tdsForm, ...tdsState };
        });

               // Sort data by timestamp, latest first
         const sortedData = mergedData.sort((a, b) => {
            const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
            const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
            return timestampB - timestampA;
          });

        setUsers(sortedData);
      } catch (error) {
        setError('Error fetching data');
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const filteredUsers = useMemo(() => {
    return users.filter(
      user =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
 
        (user.phone && user.phone.includes(searchTerm))
    );
  }, [users, searchTerm]);

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="container">
            <div className="search-bar">
              <TextField
                type="text"
                placeholder="Search by username or email or phone..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}
              >
                Search
              </Button>
            </div>

            {loading ? (
              <CircularProgress />
            ) : error ? (
              <p>{error}</p>
            ) : (
              <div className="table-wrapper">
                <table className="responsive-table">
                  <thead className="sticky-header">
                    <tr>
                      <th>Sr No.</th>
                      <th>Date & time</th>
                      <th>User Name</th>
                      <th>Email ID</th>
                      <th>Phone No.</th>
                      <th>PAN</th>
                      <th>Income Tax Site Password</th>
                      <th>TDS File Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td>{index + 1}</td>
                        <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                        <td>{user.name || 'NA'}</td>
                        <td>{user.email || 'NA'}</td>
                        <td>{user.phone || 'NA'}</td>
                        <td>{user.pan || 'NA'}</td>
                        <td>{user.incometax_password || 'NA'}</td>
                        <td className={user.fileTds ? 'completed' : 'incompleted'}>
                          {user.fileTds ? 'Completed' : 'Incomplete'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default TdsCredentials;
const css = `
/* Updated CSS for TdsCredentials component */

.incompleted {
  background-color: #ef0107;
  color: white;
}

.completed {
  background-color: #03c03c;
  color: white;
}

.even {
  background-color: #f2f2f2; /* Light gray background for even rows */
}

.odd {
  background-color: #ffffff; /* White background for odd rows */
}

.table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-height: calc(100vh - 300px); /* Adjust based on your layout */
  position: relative;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 12px;
  text-align: left;
  white-space: nowrap;
  border: 1px solid #ddd;
}

.sticky-header th {
  position: sticky;
  top: 0;
  background-color: lightgreen;
  font-weight: bold;
  z-index: 2; /* Ensure header stays above table rows */
}

.sticky-header th {
  background-color: lightgreen; /* Header background color */
}

`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
