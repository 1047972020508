/* eslint-disable react/jsx-pascal-case */

import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import OTP from './components/pages/OTP';
import UserDetails from './components/pages/UserDetails';
import Login from './components/pages/Login';
import RoleAssignmentForm from './components/pages/Role_Assignment';
import EmployeeStatus from './components/pages/EmployeeStatus';
import Upload_FormA26 from './components/pages/Upload_FormA26';
import UpdateProfile from './components/pages/UpdateProfile';
import Userstatus from './components/pages/UserStatus';
import TdsCredentials from './components/pages/TdsCredentials';
import PaymentStatus from './components/pages/Payment';
import FinalOtp from './components/pages/Finalotp';
import UploadBanners from './components/pages/UploadBanner';
import UserActionPage from './components/pages/UserAction';
import ScreenAllow from './components/pages/ScreenAllow';




function App() {
  const [loggedInUserRole, setLoggedInUserRole] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const [user , setUser]= useState([]);

  return (
    <div>
      <Routes>
        
          <>
           
            <Route
              path="/dashboard"
              element={<Dashboard loggedInUserRole={loggedInUserRole} userPermissions={userPermissions} />}
            />
            <Route path="/admin/user/details" element={<UserDetails userPermissions={userPermissions} />} />
            <Route path="/admin/user/status" element={<Userstatus userPermissions={userPermissions} />} />
            <Route path="/admin/otp" element={<OTP userPermissions={userPermissions}/>} />
            <Route path="/admin/addRole" element={<RoleAssignmentForm  userPermissions={userPermissions} />} />
            <Route path="/admin/employee/details" element={<EmployeeStatus  userPermissions={userPermissions} />} />
            <Route path="/admin/tdsdetails" element={<Upload_FormA26 userPermissions={userPermissions}/>} />
            <Route path="/update/profile" element={<UpdateProfile userPermissions={userPermissions} user={user}/>} />
            <Route path='/admin/tds/credential' element={<TdsCredentials userPermissions={userPermissions} /> }/>
            <Route path='/admin/tds/payment' element={<PaymentStatus userPermissions={userPermissions} /> }/>
            <Route path= '/admin/final-otp' element={<FinalOtp userPermissions={userPermissions} /> }/>
            <Route path='/admin/banner' element={<UploadBanners userPermissions={userPermissions}/>}/>
            <Route path='/admin/user/action' element={<UserActionPage userPermissions={userPermissions}/>}/>
            <Route path='/admin/user/screen/permision' element={<ScreenAllow  userPermissions={userPermissions}/>}/>
          </>
         
          <Route path="/" element={<Login setLoggedInUserRole={setLoggedInUserRole} setUserPermissions={setUserPermissions} setUser={setUser} />} />
       
      </Routes>
    </div>
  );
}

export default App;
