import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Button, TextField } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const firestore = getFirestore(app);

const UserDetails = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tableVisible, setTableVisible] = useState(false); // State to manage table visibility

  const fetchData = async () => {
    try {
      // Fetch users
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch TDS Form data
      const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
      const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data()   ,     timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
      }));

      // Merge data based on userId
      const mergedData = usersData.map(user => {
        const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
        return { ...user, ...tdsForm };
      });

      // Sort data by timestamp, latest first
      const sortedData = mergedData.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
        const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
        return timestampB - timestampA;
      });

      setUsers(sortedData);
      setTableVisible(true); // Show the table after data fetch
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every 1 minute
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const filteredUsers = users.filter(
    user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.phone && user.phone.includes(searchTerm))
  );

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className="container">
            <div className="search-bar">
              <TextField
                type="text"
                placeholder="Search by username or email or phone..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={fetchData}
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>

            {tableVisible && ( // Show table only when data is fetched
              <div className="table-wrapper">
                <table className="responsive-table">
                  <thead className="sticky-header">
                    <tr>
                      <th>Sr No.</th>
                      <th>Date & Time</th>
                      <th>User Name</th>
                      <th>Email ID</th>
                      <th>Phone No.</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone Number Linked To Aadhar No.</th>
                      <th>Email</th>
                      <th>DOB</th>
                      <th>Pancard Number</th>
                      <th>Address</th>
                      <th>Pincode</th>
                      <th>Bank Account Number</th>
                      <th>Bank IFSC Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <TableRowWithSlideEffect key={user.id} user={user} index={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

const TableRowWithSlideEffect = ({ user, index }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set isVisible to true after component mounts to trigger animation
    setIsVisible(true);
  }, []);

  return (
    <tr className={`table-row ${index % 2 === 0 ? 'even' : 'odd'} ${isVisible ? 'slide-in-left' : ''}`}>
      <td>{index + 1}</td>
      <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.phone}</td>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.phone}</td>
      <td>{user.email}</td>
      <td>{user.dob}</td>
      <td>{user.pan}</td>
      <td>{user.address}</td>
      <td>{user.pin_code}</td>
      <td>{user.bank_account}</td>
      <td>{user.bank_ifsc}</td>
    </tr>
  );
};

export default UserDetails;

// CSS styles for the status fields and animation
const css = `
/* General styles */
.even {
  background-color: #f2f2f2; /* Light gray background for even rows */
}

.odd {
  background-color: #ffffff; /* White background for odd rows */
}

/* Animation for sliding in table rows */
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.table-row.slide-in-left {
  animation: slide-in-left 0.2s ease forwards;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: lightgreen; /* Sticky header background color */
  z-index: 10;
  border-bottom: 1px solid #ddd; /* Bottom border for sticky header */
}

.table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-height: calc(100vh - 240px); /* Maximum height for table wrapper */
}

.responsive-table {
  width: 100%; /* Full width for the table */
  border-collapse: collapse; /* Collapse borders */
}

.responsive-table th,
.responsive-table td {
  padding: 12px; /* Padding for table cells */
  text-align: left; /* Align text to the left */
  white-space: wrap; /* Prevent text wrapping */
}

.responsive-table th {
  background-color: lightgreen; /* Light gray background for headers */
  font-weight: bold; /* Bold font for headers */
  border: 1px solid #ddd; /* Border for table headers */
}

`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
