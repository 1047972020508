import React from 'react';
import Box from '@mui/material/Box';
import NavbarDashboard from './NavbarDashboard';
import DashHome from './DashHome';
import ChartOwner from './ChartOwner';
import ResponsiveDrawer from './Sidebar';
import { CssBaseline } from '@mui/material';


function Dashboard({ loggedInUserRole, userPermissions }) {
  
  return (
    <div>
      <CssBaseline />
      <NavbarDashboard />
      <Box height={30} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {loggedInUserRole === 'admin' ? (
            <>
              <DashHome />
              <ChartOwner />
            </>
          ) : (
            // Add other role-specific components here
            <div>Role-specific dashboard content</div>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default Dashboard;
