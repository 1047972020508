import React, { useEffect, useState } from "react";
import { app } from '../../firebase';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler);

function ChartOwner() {
  const [usersTdsData, setUsersTdsData] = useState({
    labels: ['Users', 'TDS Forms'],
    datasets: []
  });

  const [tdsPaymentData, setTdsPaymentData] = useState({
    labels: ['TDS Forms', 'Payments'],
    datasets: []
  });

  const fetchData = async () => {
    try {
      const firestore = getFirestore(app);
      
      // Fetch users
      const usersQuery = query(collection(firestore, "users"));
      const usersSnapshot = await getDocs(usersQuery);
      const usersCount = usersSnapshot.size;

      // Fetch TDS forms
      const tdsFormQuery = query(collection(firestore, "tds_state"), where("tdsformfilluped", "==", true));
      const tdsFormSnapshot = await getDocs(tdsFormQuery);
      const tdsFormCount = tdsFormSnapshot.size;

      // Fetch Payment statuses
      const paymentStatusQuery = query(collection(firestore, "tds_state"), where("paymentstatus", "==", true));
      const paymentStatusSnapshot = await getDocs(paymentStatusQuery);
      const paymentStatusCount = paymentStatusSnapshot.size;

      // Set data for Users vs TDS Forms Area Chart
      setUsersTdsData({
        labels: ['Users', 'TDS Forms'],
        datasets: [
          {
            label: 'Count',
            data: [usersCount, tdsFormCount],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
          },
        ]
      });

      // Set data for TDS Forms vs Payments Bar Chart
      setTdsPaymentData({
        labels: ['TDS Forms', 'Payments'],
        datasets: [
          {
            label: 'Count',
            data: [tdsFormCount, paymentStatusCount],
            backgroundColor: ['rgba(153,102,255,0.2)', 'rgba(255,159,64,0.2)'],
            borderColor: ['rgba(153,102,255,1)', 'rgba(255,159,64,1)'],
            borderWidth: 2
          },
        ]
      });

    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 600000); // 600,000 ms = 10 minutes
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="container mx-auto my-5">
      <div className="row mb-5">
        <div className="col-lg-12">
          <h3>Ratio between Users vs TDS Forms Fillup</h3>
          <Line
            data={usersTdsData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'Users vs TDS Forms',
                },
              },
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
        <h3>Ratio between TDS Forms Fillup vs Payment</h3>
          <Bar
            data={tdsPaymentData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: 'TDS Forms vs Payment Status',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ChartOwner;
