import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct

import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Button } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const firestore = getFirestore(app);

const UserStatus = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      // Fetch users
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
      const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ userId: doc.id, ...doc.data() ,timestamp: doc.data().timestamp?.toDate()}));
      
      // Fetch TDS State data
      const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));
      const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Merge data based on userId
      const mergedData = usersData.map(user => {
        const tdsState = tdsStateData.find(tds => tds.id === user.id) || {};
        const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
        return { ...user, ...tdsForm, ...tdsState };
      });

         // Sort data by timestamp, latest first
         const sortedData = mergedData.sort((a, b) => {
          const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
          const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
          return timestampB - timestampA;
        });

      setUsers(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 600000); // Refresh every 10 minutes
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const filteredUsers = users.filter(
    user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
     user.email.toLowerCase().includes(searchTerm.toLowerCase())||
    (user.phone && user.phone.includes(searchTerm))
  );

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
          <div className="container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by username or email or phone number..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={fetchData}
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>

            <div className="table-wrapper">
              <table className="responsive-table">
                <thead>
                  <tr>
                    <th className="sticky-header">Sr No.</th>
                    <th className="sticky-header"> Date & time</th>
                    <th className="sticky-header">User Name</th>
                    <th className="sticky-header">Email ID</th>
                    <th className="sticky-header">Phone No.</th>
                    <th className="sticky-header">TDS Form Status</th>
                    <th className="sticky-header">Income Tax Website Registration Status</th>
                    <th className="sticky-header">TDS File Status</th>
                    <th className="sticky-header">TDS Details</th>
                    <th className="sticky-header">Payment Status</th>
                    <th className="sticky-header">E-verification</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td>{index + 1}</td>
                      <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td style={{ backgroundColor: user.tdsformfilluped ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.tdsformfilluped ? 'Complete' : 'Incomplete'}
                      </td>
                      <td style={{ backgroundColor: user.incomesiteregistration ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.incomesiteregistration ? 'Complete' : 'Incomplete'}
                      </td>
                      <td style={{ backgroundColor: user.fileTds ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.fileTds ? 'Complete' : 'Incomplete'}
                      </td>
                      <td style={{ backgroundColor: user.tdsA26s ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.tdsA26s ? 'Complete' : 'Incomplete'}
                      </td>
                      <td style={{ backgroundColor: user.paymentstatus ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.paymentstatus ? 'Complete' : 'Incomplete'}
                      </td>
                      <td style={{ backgroundColor: user.finalotpstatus ? '#03C03C' : '#EF0107', color: 'white' }}>
                        {user.finalotpstatus ? 'Complete' : 'Incomplete'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default UserStatus;

// CSS styles to make the header sticky
const css = `
.sticky-header {
  position: sticky;
  top: 0;
  background-color: lightgreen;
  z-index: 100;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table-wrapper {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.even {
  background-color: #f2f2f2;
}

.odd {
  background-color: #ffffff;
}
`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
