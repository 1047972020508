import React, { useEffect, useState } from "react";
import { app } from '../../firebase';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { AiOutlineUser, AiOutlineForm, AiOutlineDollarCircle, AiOutlineTeam } from 'react-icons/ai';
import { BsFileEarmarkArrowUp } from 'react-icons/bs'; // Import icons from react-icons

function DashHome() {
  const [activeUsers, setActiveUsers] = useState(0);
  const [tdsFormCount, setTdsFormCount] = useState(0);
  const [paymentStatusCount, setPaymentStatusCount] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [itRegistrationsCount, setItRegistrationsCount] = useState(0);
  const [uploadedTdsDetailsCount, setUploadedTdsDetailsCount] = useState(0); // State for uploaded TDS details

  useEffect(() => {
    const fetchData = async () => {
      try {
        const firestore = getFirestore(app);

        // Fetch active users
        const usersQuery = query(collection(firestore, "users"));
        const usersSnapshot = await getDocs(usersQuery);
        setActiveUsers(usersSnapshot.size);

        // Fetch TDS forms count
        const tdsFormQuery = query(collection(firestore, "tds_state"), where("tdsformfilluped", "==", true));
        const tdsFormSnapshot = await getDocs(tdsFormQuery);
        setTdsFormCount(tdsFormSnapshot.size);

        // Fetch payment status count
        const paymentStatusQuery = query(collection(firestore, "tds_state"), where("paymentstatus", "==", true));
        const paymentStatusSnapshot = await getDocs(paymentStatusQuery);
        setPaymentStatusCount(paymentStatusSnapshot.size);

        // Fetch total employees
        const employeesQuery = query(collection(firestore, "employee"));
        const employeesSnapshot = await getDocs(employeesQuery);
        setTotalEmployees(employeesSnapshot.size);

        // Fetch IT registrations count
        const itRegistrationsQuery = query(collection(firestore, "tds_state"), where("incomesiteregistration", "==", true));
        const itRegistrationsSnapshot = await getDocs(itRegistrationsQuery);
        setItRegistrationsCount(itRegistrationsSnapshot.size);

        // Fetch uploaded TDS details count (based on specified conditions)
        const uploadedTdsDetailsQuery = query(collection(firestore, "tds_state"),
          where("tdsA26s", "==", true)
        );
        const uploadedTdsDetailsSnapshot = await getDocs(uploadedTdsDetailsQuery);
        setUploadedTdsDetailsCount(uploadedTdsDetailsSnapshot.size);

      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto my-5">
      <div className="row">
        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <AiOutlineUser size={58} color="black" />
                    <div>
                      <p className="card-category">Register User</p>
                      <h4 className="card-title">{activeUsers}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                Registration Completed
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <AiOutlineForm size={58} color="black" />
                    <div>
                      <p className="card-category">TDS Form Filled</p>
                      <h4 className="card-title">{tdsFormCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                TDS Forms Filled
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <AiOutlineDollarCircle size={58} color="black" />
                    <div>
                      <p className="card-category">Payment Done</p>
                      <h4 className="card-title">{paymentStatusCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                Payments Completed
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <AiOutlineTeam size={58} color="black" />
                    <div>
                      <p className="card-category">Total Employees</p>
                      <h4 className="card-title">{totalEmployees}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                Employees Registered
              </div>
            </div>
          </div>
        </div>


        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <svg
                      width="58"
                      height="58"
                      viewBox="0 0 58 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.83337 19.3334C4.83337 17.8466 6.01333 16.6667 7.50004 16.6667H50.5C51.9868 16.6667 53.1667 17.8466 53.1667 19.3334C53.1667 20.8201 51.9868 22 50.5 22H7.50004C6.01333 22 4.83337 20.8201 4.83337 19.3334ZM7.50004 27.6667C6.01333 27.6667 4.83337 28.8466 4.83337 30.3334C4.83337 31.8201 6.01333 33 7.50004 33H50.5C51.9868 33 53.1667 31.8201 53.1667 30.3334C53.1667 28.8466 51.9868 27.6667 50.5 27.6667H7.50004ZM4.83337 41.6667C4.83337 40.1799 6.01333 39 7.50004 39H50.5C51.9868 39 53.1667 40.1799 53.1667 41.6667C53.1667 43.1534 51.9868 44.3334 50.5 44.3334H7.50004C6.01333 44.3334 4.83337 43.1534 4.83337 41.6667Z"
                        fill="black"
                      ></path>
                    </svg>
                    <div>
                      <p className="card-category">IT Registration</p>
                      <h4 className="card-title">{itRegistrationsCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                IT Registrations Completed
              </div>
            </div>
          </div>
        </div>
      
   

        {/* New Section for Uploaded TDS Details */}
        <div className="col-lg-3 col-sm-6 mx-auto my-3">
          <div className="card-stats card bg-gradient-to-r from-sky-500 to-indigo-500" style={{ color: "black" }}>
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="d-flex numbers">
                    <BsFileEarmarkArrowUp size={58} color="black" />
                    <div>
                      <p className="card-category">Uploaded TDS Details</p>
                      <h4 className="card-title">{uploadedTdsDetailsCount}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <hr />
              <div className="stats">
                <i className="far fa-calendar-alt mr-1"></i>
                Successfully Uploaded
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashHome;
