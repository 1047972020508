import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { app } from '../../firebase';
import './EmployeeStatus.css';
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Modal, TextField, Button, FormControl, FormControlLabel, Checkbox, Grid, MenuItem, Select, InputLabel } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon, Refresh as RefreshIcon } from '@mui/icons-material'; // Importing icons
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firestore = getFirestore(app);

const EmployeeStatus = ({ userPermissions }) => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: '',
    permissions: [],
    status: 'Active',
    id: '', // This will store the document ID from Firestore
  });
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch employees from Firestore
  const fetchEmployees = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'employee'));
      const employeesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeesData);
      setFilteredEmployees(employeesData); // Initialize filtered employees
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch data when the component mounts and set up an interval for auto-refresh
  useEffect(() => {
    fetchEmployees();
    const interval = setInterval(fetchEmployees, 600000); // Refresh every 10 minutes
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Handle status update and refetch data after updating
  const handleStatusUpdate = async (id, status) => {
    try {
      const employeeDoc = doc(firestore, 'employee', id);
      await updateDoc(employeeDoc, { status });
      
      fetchEmployees(); // Refetch to get the latest data after update
      toast.success('Employee status updated successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Error updating employee status');
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      const employeeDoc = doc(firestore, 'employee', id);
      await deleteDoc(employeeDoc);
      fetchEmployees(); // Refetch to get the latest data after delete
      toast.success('Employee deleted successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('Error deleting employee');
    }
  };

  // Handle edit
  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    setFormData({
      ...employee, // Set all fields from the selected employee
      id: employee.id, // Store document ID for update operation
    });
    setOpenModal(true);
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle permission change
  const handlePermissionChange = (permission) => {
    const index = formData.permissions.indexOf(permission);
    if (index === -1) {
      setFormData({
        ...formData,
        permissions: [...formData.permissions, permission],
      });
    } else {
      const updatedPermissions = [...formData.permissions];
      updatedPermissions.splice(index, 1);
      setFormData({
        ...formData,
        permissions: updatedPermissions,
      });
    }
  };

  // Handle form submission for editing
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const { id, ...updatedEmployee } = formData; // Exclude id from the updated employee data
      const employeeDoc = doc(firestore, 'employee', id);
      await updateDoc(employeeDoc, updatedEmployee);
      fetchEmployees(); // Refetch to get the latest data after update
      setOpenModal(false);
      toast.success('Employee details updated successfully', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error) {
      console.error('Error updating employee:', error);
      toast.error('Error updating employee details');
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setOpenModal(false);
    setFormData({
      name: '',
      email: '',
      password: '',
      role: '',
      permissions: [],
      status: 'Active',
      id: '', // Reset id field
    });
  };

  // Handle search
  useEffect(() => {
    const filtered = employees.filter((employee) =>
      employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      employee.id.toString().includes(searchTerm)
    );
    setFilteredEmployees(filtered);
  }, [searchTerm, employees]);

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component='main' sx={{ flexGrow: 1, p: 9 }}>
        <ToastContainer />
          <div className='container'>
            <div className='search-bar'>
              <TextField
                label='Search by name, email or ID...'
                variant='outlined'
                size='small'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button variant="contained" onClick={() => setSearchTerm('')} style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}>
                      <SearchIcon />
                    </Button>
                  )
                }}
              />
              <Button 
                variant="contained" 
                onClick={fetchEmployees} 
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }} 
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>

            <div className='table-wrapper'>
              <table className='responsive-table'>
                <thead className="sticky-header">
                  <tr>
                    <th>Name</th>
                    <th>Role</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Permissions</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>ID</th> {/* Display ID in the table */}
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.map((employee, index) => (
                    <tr key={employee.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td>{employee.name}</td>
                      <td>{employee.role}</td>
                      <td>{employee.email}</td>
                      <td>{employee.password}</td>
                      <td>{Array.isArray(employee.permissions) ? employee.permissions.join(', ') : employee.permissions}</td>
                      <td style={{ color: employee.status === 'Active' ? 'green' : 'red' }}>
                        {employee.status}
                      </td>
                      <td style={{ display: 'flex', gap: '8px' }}> {/* Adjust spacing between buttons */}
                        <Button onClick={() => handleEdit(employee)} startIcon={<EditIcon />} variant="contained" color="primary"> {/* Change color to primary (blue) */}
                          Edit
                        </Button>
                        <Button onClick={() => handleDelete(employee.id)} startIcon={<DeleteIcon />} variant="contained" color="secondary"> {/* Change color to secondary (red) */}
                          Delete
                        </Button>
                      </td>
                      <td>{employee.id}</td> {/* Display document ID */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>

      {/* Modal for editing employee details */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className='modal-box'>
          <h2>Edit Employee Details</h2>
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Name'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Password'
                  name='password'
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Role</InputLabel>
                  <Select
                    name='role'
                    value={formData.role}
                    onChange={handleChange}
                    fullWidth
                    required
                  >
                    <MenuItem value='Admin'>Admin</MenuItem>
                    <MenuItem value='Manager'>Manager</MenuItem>
                    <MenuItem value='Employee'>Employee</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component='fieldset'>
                  <h3>Permissions</h3>
                  {['Create', 'Read', 'Update', 'Delete'].map((permission) => (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Checkbox
                          checked={formData.permissions.includes(permission)}
                          onChange={() => handlePermissionChange(permission)}
                        />
                      }
                      label={permission}
                    />
                  ))}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name='status'
                    value={formData.status}
                    onChange={handleChange}
                    fullWidth
                    required
                  >
                    <MenuItem value='Active'>Active</MenuItem>
                    <MenuItem value='Inactive'>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant='contained' onClick={handleCloseModal} sx={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button variant='contained' color='primary' type='submit'>
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeStatus;
