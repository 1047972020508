import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { app } from '../../firebase'; // Ensure this path is correct
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import './UpdateProfile.css'; // Import your custom styles here
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const firestore = getFirestore(app);
const storage = getStorage(app);

const UpdateProfile = ({ userPermissions, user }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    profileImage: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.id) {
        try {
          const userDoc = await getDoc(doc(firestore, 'employee', user.id));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let profileImageUrl = '';

            // Fetch the profile image URL from storage
            if (userData.profileImage) {
              const imageRef = ref(storage, userData.profileImage);
              profileImageUrl = await getDownloadURL(imageRef);
            }

            setFormData({
              name: userData.name,
              email: userData.email,
              password: userData.password,
              profileImage: profileImageUrl,
            });
          } else {
            console.error('User document not found');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        console.error('User ID is undefined');
      }
    };

    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      profileImage: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, profileImage } = formData;
    
    let profileImageUrl = formData.profileImage;

    if (profileImage instanceof File) {
      try {
        const storageRef = ref(storage, `images/${user.id}/${profileImage.name}`);
        await uploadBytes(storageRef, profileImage);
        profileImageUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Error uploading profile image');
        return;
      }
    }

    try {
      const userRef = doc(firestore, 'employee', user.id);
      await updateDoc(userRef, {
        name,
        email,
        password,
        profileImage: `images/${user.id}/${profileImage.name}`, // Save the path to the image
      });
  
      toast.success('Profile updated successfully');
      setFormData({
        ...formData,
        profileImage: profileImageUrl, // Update profile image URL in local state
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error updating profile');
    }
  };

  return (
    <div className="update-profile-container">
      <NavbarDashboard />
      <Box height={50} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component='main' sx={{ flexGrow: 1, p: 8 }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={4}>
              <Card className="profile-card">
                <CardContent>
                  <div className="profile-header">
                    <img
                      src={formData.profileImage ? formData.profileImage : '/default-profile.jpg'}
                      alt="Profile"
                      className="profile-picture"
                    />
                    <h2>{formData.name}</h2>
                    <h5>{formData.email}</h5>
                    <h5>{user.role}</h5>
                    <h5>{user.status}</h5>
                  </div>
                  <Button
                    variant='contained'
                    color='success'
                    onClick={() => document.getElementById('profileImage').click()}
                    startIcon={<PhotoCamera />}
                    fullWidth
                    className="upload-photo-button"
                  >
                    Upload Profile Photo
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card className="update-form-card">
                <CardHeader title='Update Profile' />
                <CardContent>
                  <form onSubmit={handleSubmit} className="update-form">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Name'
                          name='name'
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Email'
                          name='email'
                          type='email'
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Password'
                          name='password'
                          type='password'
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <input
                          type='file'
                          accept='image/*'
                          id='profileImage'
                          onChange={handleFileChange}
                          style={{ display: 'none' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='success'
                          fullWidth
                        >
                          Update Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ToastContainer />
    </div>
  );
};

export default UpdateProfile;
