import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct

import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Button, Select, MenuItem } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import axios from 'axios';

const FinalOtp = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      const firestore = getFirestore(app);

      // Fetch users
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch TDS State data
      const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));
      const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch TDS Details data
      const tdsDetailsSnapshot = await getDocs(collection(firestore, 'tds_details'));
      const tdsDetailsData = tdsDetailsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Fetch TDS return year data
      const tdsYearSnapshot = await getDocs(collection(firestore, 'tds_refund_Years'));
      const tdsYearData = tdsYearSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

           // Fetch TDS Form data
           const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
           const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data()   ,     timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
           }));

      // Fetch TDS Refund OTP data
      const tdsRefundOtpSnapshot = await getDocs(collection(firestore, 'tds_refund_otp'));
      const tdsRefundOtpData = tdsRefundOtpSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Merge data based on userId
      const mergedData = usersData.map(user => {
        const tdsState = tdsStateData.find(tds => tds.id === user.id) || {};
        const tdsDetails = tdsDetailsData.find(tds => tds.id === user.id) || {};
        const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
        const tdsRefundOtp = tdsRefundOtpData.find(tds => tds.id === user.id) || {};
        const tdsYear = tdsYearData.find(tds => tds.id === user.id) || {};

        const tdsData2023 = tdsDetails.tdsData2023 || [];
        const tdsData2024 = tdsDetails.tdsData2024 || [];
        const totalTds2023 = tdsData2023.reduce((total, item) => total + Number(item.tds || 0), 0);
        const totalTds2024 = tdsData2024.reduce((total, item) => total + Number(item.tds || 0), 0);
        const totalTds = totalTds2023 + totalTds2024;

        return {
          ...user,
          tdsState,
          ...tdsForm,
          tdsDetails,
          tdsYear,
          tdsRefundOtp,
          tdsData2023,
          tdsData2024,
          totalTds,
        };
      });
  // Sort data by timestamp, latest first
  const sortedData = mergedData.sort((a, b) => {
    const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
    const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
    return timestampB - timestampA;
  });

  setUsers(sortedData);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 300000); // Refresh every 1 minute
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const sendNotification = async (title, message, fcmToken) => {
    try {
      await axios.post('https://moneycartadmin.onrender.com/send-notification', {
        title,
        message,
        fcmToken,
      });
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleEnableOtp = async (userId) => {
    try {
      const firestore = getFirestore(app);
      const userRef = doc(firestore, 'tds_state', userId);
      await updateDoc(userRef, { enableOtp: true });
      const updatedUsers = users.map(user => user.id === userId ? { ...user, tdsState: { ...user.tdsState, enableOtp: true } } : user);
      setUsers(updatedUsers);

      // Find the user to get the FCM token
      const user = users.find(user => user.id === userId);
      if (user) {
        // Send notification
        await sendNotification(
          'e-verified OTP',
          'Your ITR has been filed and it now needs to be e-verified to be submitted to the income tax department. Please enter the OTP received on your mobile number linked to your Aadhaar card.',
          user.fcmToken // Assuming you have FCM token stored in the user object
        );
      }
    } catch (error) {
      console.error('Error enabling OTP:', error);
    }
  };

  const handleFinalOtpStatus = async (userId, status) => {
    try {
      const firestore = getFirestore(app);
      const userRef = doc(firestore, 'tds_state', userId);
      await updateDoc(userRef, { finalotpstatus: status === 'true' });
      const updatedUsers = users.map(user => user.id === userId ? { ...user, tdsState: { ...user.tdsState, finalotpstatus: status === 'true' } } : user);
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error updating finalotpstatus:', error);
    }
  };

  const filteredUsers = users.filter(
    user =>
      (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
          <div className="container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by username or email..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={fetchData}
                style={{ backgroundColor: 'lightgreen', marginLeft: '10px' }}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>

            <div className="table-wrapper">
              <table className="responsive-table">
                <thead>
                  <tr className="sticky-header">
                    <th>Sr No.</th>
                    <th>Date & Time</th>
                    <th>User Name</th>
                    <th>Email ID</th>
                    <th>Phone No.</th>
                    <th>Payment Status</th>
                    <th>Payment Confirmation</th>
                    <th>TDS 2023</th>
                    <th>TDS 2024</th>
                    <th>User selected Previous Year TDS</th>
                    <th>Total TDS</th>
                    <th>Enable OTP</th>
                    <th>OTP</th>
                    <th>Claim Apply</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user, index) => (
                    <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                      <td>{index + 1}</td>
                      <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td className={user.tdsState.paymentstatus ? 'Complete' : 'Incomplete'}>
                        {user.tdsState.paymentstatus ? 'Complete' : 'Incomplete'}
                      </td>
                      <td className={user.tdsState.paymentConfirmation === 'true' ? 'Complete' : 'Incomplete'}>
                        {user.tdsState.paymentConfirmation === 'true' ? 'Complete' : 'Incomplete'}
                      </td>
                      <td>{user.tdsData2023.map((tds, idx) => (
                        <div key={idx}>{`TDS: ${tds.tds}`}</div>
                      ))}</td>
                      <td>{user.tdsData2024.map((tds, idx) => (
                        <div key={idx}>{`TDS: ${tds.tds}`}</div>
                      ))}</td>
                      <td>{user.tdsYear.previousYear ? 'Yes' : 'No'}</td>
                      <td>{user.totalTds}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEnableOtp(user.id)}
                          disabled={user.tdsState.enableOtp}
                        >
                          {user.tdsState.enableOtp ? 'Enabled' : 'Enable OTP'}
                        </Button>
                      </td>
                      <td>{user.tdsRefundOtp.otp || 'N/A'}</td>
                      <td>
                        <Select
                          style={{ color: 'white', backgroundColor: user.tdsState.finalotpstatus ? '#03C03C' : '#EF0107' }}
                          value={user.tdsState.finalotpstatus ? 'true' : 'false'}
                          onChange={(e) => handleFinalOtpStatus(user.id, e.target.value)}
                        >
                          <MenuItem value="true">Successfully Apply</MenuItem>
                          <MenuItem value="false">Incomplete</MenuItem>
                        </Select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default FinalOtp;

// CSS styles for the table
const css = `
  .Incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }

  .Complete, .Passed, .successfully_apply {
    background-color: #03C03C;
    color: white;
    border-style: solid;
  }
    
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 10;
  }

  .Failed, .incomplete {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }

  .even {
    background-color: #f2f2f2;
  }

  .odd {
    background-color: #ffffff;
  }
`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);