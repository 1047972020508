// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBhiWE68frF9hlkbmcTAQ5p4ycidm6xSWQ",
  authDomain: "money-cart-326ac.firebaseapp.com",
  projectId: "money-cart-326ac",
  storageBucket: "money-cart-326ac.appspot.com",
  messagingSenderId: "758043186313",
  appId: "1:758043186313:web:ad7a2392e9a46801ede258",
  measurementId: "G-9CCJ5KWSKN"
};

export const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: "BGYCsdCmm11zeL2hm_7bRYXyVP1okLkCGeeVEbMZrYOInCfXI1ga7ZmRaNWrY8nvdMeg6za2kCne_w3ZRWr0KBA" }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      // Perform any other steps you want to do with the token
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { firestore, storage };