import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import { CssBaseline, IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAppStore } from './appStore';

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  backgroundColor: '#90EE90', // Light green background
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      backgroundColor: '#90EE90', // Light green background
      color: '#000000', // Dark black text
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: '#90EE90', // Light green background
      color: '#000000', // Dark black text
    },
  }),
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  '&:hover, &.Mui-selected': {
    backgroundColor: '#ffffff', // White background on hover and when selected
    '& .MuiListItemText-root': {
      color: '#000000', // Black text on hover and when selected
    },
    '& .MuiListItemIcon-root': {
      color: '#000000', // Black icons on hover and when selected
    },
  },
}))

function ResponsiveDrawer({ userPermissions }) {
  const theme = useTheme();
  const open = useAppStore((state) => state.dopen);
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarItems = [
    { label: 'Dashboard', path: '/dashboard', permission: 'viewDashboard', icon: <DashboardIcon /> },
    { label: 'Role Assign', path: '/admin/addRole', permission: 'assignRole', icon: <AssignmentIcon /> },
    { label: 'Employee Details', path: '/admin/employee/details', permission: 'viewEmployee', icon: <PersonIcon /> },
    { label: 'User Status', path: '/admin/user/status', permission: 'viewUserStatus', icon: <PersonIcon /> },
    { label: 'Users Details', path: '/admin/user/details', permission: 'viewUsers', icon: <PersonIcon /> },
    { label: 'User Action', path: '/admin/user/action', permission: 'userAction', icon: <PersonIcon /> },
    { label: 'IT Registration', path: '/admin/otp', permission: 'TDSRegistrationDetails', icon: <CallIcon /> },
    { label: 'IT Credentials', path: '/admin/tds/credential', permission: 'TDSCredentialsDetails', icon: <CallIcon /> },
    { label: 'TDS Details', path: '/admin/tdsdetails', permission: 'TDSDetails', icon: <CallIcon /> },
    { label: 'Payment Status', path: '/admin/tds/payment', permission: 'viewPaymentStatus', icon: <PaymentIcon /> },
    { label: 'E-Verification Otp', path: '/admin/final-otp', permission: 'ReturnOtp', icon: <PaymentIcon /> },
    {label: 'Screen Permission' , path : '/admin/user/screen/permision', permission: 'screenPermission' ,icon:<AssignmentIcon/> },
    { label: 'Update Profile', path: '/update/profile', permission: 'updateProfile', icon: <AccountCircleIcon /> },
    { label: 'Upload Banner', path: '/admin/banner', permission: 'uploadBanner', icon: <AccountCircleIcon /> },
    { label: 'Log Out', path: '/', permission: '', icon: <ExitToAppIcon /> }, // No permission required for Log Out
  ];

  // Replace icons for specific labels
  const updatedSidebarItems = sidebarItems.map(item => {
    switch (item.label) {
      case 'Employee Details':
      case 'User Status':
      case 'Users Details':
      case 'User Action':
        return { ...item, icon: <PersonIcon /> };
      default:
        return item;
    }
  });

  // Filter sidebar items based on permissions
  const filteredSidebarItems = updatedSidebarItems.filter(item => !item.permission || userPermissions.includes(item.permission));

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={() => useAppStore.setState({ dopen: !open })}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {filteredSidebarItems.map((item, index) => (
            <ListItemButtonStyled
              key={index}
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path} // Highlight the selected item
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButtonStyled>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

export default ResponsiveDrawer;
