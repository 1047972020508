import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, FormControl, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Select, MenuItem, InputLabel } from '@mui/material';
import { collection, getFirestore, getDocs, doc, setDoc } from 'firebase/firestore';
import { app } from '../../firebase';
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const lightGreen = '#90EE90'; // Light green color

const firestore = getFirestore(app);

const predefinedRoles = {
  Admin: ['viewDashboard', 'assignRole', 'viewEmployee', 'viewUserStatus', 'viewUsers','userAction', 'TDSRegistrationDetails', 'TDSCredentialsDetails', 'TDSDetails', 'viewPaymentStatus', 'screenPermission', 'updateProfile', 'ReturnOtp','uploadBanner', 'logOut'],
  'Registration team': ['viewUserStatus', 'viewUsers','TDSRegistrationDetails', 'TDSCredentialsDetails', 'TDSDetails','screenPermission', 'updateProfile'],
  'ITR filing team': ['TDSCredentialsDetails', 'viewPaymentStatus', 'ReturnOtp', 'updateProfile', 'screenPermission'],
  'Marketing team': ['updateProfile', 'uploadBanner']
};

const RoleAssignmentForm = ({ userPermissions }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    password: '',
    role: '',
    permissions: [],
  });

  const [userRoles, setUserRoles] = useState([]);
  const [customRole, setCustomRole] = useState('');
  const [showCustomRoleInput, setShowCustomRoleInput] = useState(false);

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const fetchUserRoles = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'employee'));
      const roles = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUserRoles(roles);
    } catch (error) {
      toast.error('Error fetching user roles:', error);
      console.error('Error fetching user roles:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'role') {
      if (value === 'Custom') {
        setShowCustomRoleInput(true);
        setFormData({
          ...formData,
          role: '',
          permissions: []
        });
      } else {
        setShowCustomRoleInput(false);
        setFormData({
          ...formData,
          role: value,
          permissions: predefinedRoles[value] || []
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePermissionChange = (permission) => {
    const index = formData.permissions.indexOf(permission);
    if (index === -1) {
      setFormData({
        ...formData,
        permissions: [...formData.permissions, permission],
      });
    } else {
      const updatedPermissions = [...formData.permissions];
      updatedPermissions.splice(index, 1);
      setFormData({
        ...formData,
        permissions: updatedPermissions,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newDocRef = doc(collection(firestore, 'employee')); // Create a new document reference with a unique ID
      await setDoc(newDocRef, {
        id: newDocRef.id, // Include the document ID in the data
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        password: formData.password,
        role: showCustomRoleInput ? customRole : formData.role,
        permissions: formData.permissions,
        status: 'Active'
      });
      toast.success('Role assigned successfully')
     
      setFormData({
        name: '',
        email: '',
        mobile: '',
        password: '',
        role: '',
        permissions: [],
      });
      setCustomRole('');
      fetchUserRoles();
    } catch (error) {
      console.error('Error assigning role:', error);
      toast.error('Error assigning role. Please try again.');
    }
  };

  return (
    <div>
      <NavbarDashboard />
      <Box height={70} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <ToastContainer />
          <Typography variant="h4" gutterBottom>
            Assign Role to User
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={{ border: '2px solid #ccc', borderRadius: '8px', padding: '10px' }}>
                <Typography variant="h5" gutterBottom>
                  Enter User Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="name"
                        label="Name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="email"
                        label="Email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="mobile"
                        label="Mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="password"
                        label="Password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth >
                        <InputLabel>Role</InputLabel>
                        <Select
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                        >
                          {Object.keys(predefinedRoles).map((role) => (
                            <MenuItem key={role} value={role}>{role}</MenuItem>
                          ))}
                          <MenuItem value="Custom">Other (Custom Role)</MenuItem>
                        </Select>
                      </FormControl>
                      {showCustomRoleInput && (
                        <TextField
                          name="customRole"
                          label="Custom Role"
                          value={customRole}
                          onChange={(e) => setCustomRole(e.target.value)}
                          fullWidth
                          required
                          sx={{ mt: 2 }}
                        />
                      )}
                    </Grid>
                    {showCustomRoleInput && (
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', ml: 3 }}>
                            {['viewDashboard', 'assignRole', 'viewEmployee', 'viewUserStatus','userAction', 'viewUsers', 'TDSRegistrationDetails', 'TDSCredentialsDetails', 'TDSDetails', 'screenPermission', 'viewPaymentStatus', 'updateProfile', 'ReturnOtp','uploadBanner', 'logOut'].map((permission) => (
                              <FormControlLabel
                                key={permission}
                                control={<Checkbox checked={formData.permissions.includes(permission)} onChange={() => handlePermissionChange(permission)} />}
                                label={permission}
                                sx={{ mr: 1, minWidth: '50%' }} // Adjust spacing and width
                              />
                            ))}
                          </Box>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: lightGreen, color: 'white' }}
                      >
                        Assign Role
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ border: '2px solid #ccc', borderRadius: '8px', padding: '16px' }}>
                <Typography variant="h5" gutterBottom>
                  Assigned Roles
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Mobile</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Permissions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userRoles.map((userRole) => (
                        <TableRow key={userRole.id}>
                          <TableCell>{userRole.name}</TableCell>
                          <TableCell>{userRole.email}</TableCell>
                          <TableCell>{userRole.mobile}</TableCell>
                          <TableCell>{userRole.password}</TableCell>
                          <TableCell>{userRole.role}</TableCell>
                          <TableCell>{Array.isArray(userRole.permissions) ? userRole.permissions.join(', ') : userRole.permissions}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default RoleAssignmentForm;
