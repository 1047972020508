import React, { useState, useEffect } from 'react';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from 'firebase/storage';
import { app } from '../../firebase'; // Ensure this path is correct
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import ResponsiveDrawer from '../Dashboard/Sidebar';
import { Box, Button, Typography, Grid, Card, CardContent, CardActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify'; // Import toast functions
import 'react-toastify/dist/ReactToastify.css';
import './BannerUpload.css';

const storage = getStorage(app);
const bannersRef = ref(storage, 'banners');

const BannerUpload = ({ userPermissions }) => {
  const [banners, setBanners] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const result = await listAll(bannersRef);
      const bannerList = await Promise.all(result.items.map(async (itemRef) => {
        const downloadURL = await getDownloadURL(itemRef);
        return { name: itemRef.name, url: downloadURL };
      }));
      setBanners(bannerList);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  const handleUpload = () => {
    const uploadTasks = files.map((file) => {
      const storageRef = ref(storage, `banners/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          (error) => {
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ name: file.name, url: downloadURL });
          }
        );
      });
    });

    Promise.all(uploadTasks)
      .then((uploadedBanners) => {
        setBanners([...banners, ...uploadedBanners]);
        setFiles([]);
        toast.success('Banners uploaded successfully');
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        toast.error('Error uploading banners');
      });
  };

  const handleDelete = async (name) => {
    try {
      await deleteObject(ref(storage, `banners/${name}`));
      const updatedBanners = banners.filter((banner) => banner.name !== name);
      setBanners(updatedBanners);
      toast.success('Banner deleted successfully');
    } catch (error) {
      console.error('Error deleting banner:', error);
      toast.error('Error deleting banner');
    }
  };

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <ToastContainer />
          <div className="container">
            <Typography variant="h4" gutterBottom>
              Upload Banners
            </Typography>
            <input type="file" multiple onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleUpload}>
              Upload
            </Button>

            <Grid container spacing={3} className="banners-list">
              {banners.map((banner, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card className="banner-card">
                    <img src={banner.url} alt={banner.name} className="banner-image" />
                    <CardContent>
                      <Typography variant="subtitle1" component="div">
                        {banner.name}
                      </Typography>
                    </CardContent>
                    <CardActions className="card-actions">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(banner.name)}
                        className="delete-button"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default BannerUpload;
