import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { app } from '../../firebase';
import React, { useState } from 'react';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/bg-green.webp';
import logo from '../../assets/tb-logo.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firestore = getFirestore(app);

const Login = ({ setLoggedInUserRole, setUserPermissions, setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const collectionRef = collection(firestore, 'employee');
      const que = query(collectionRef, where('email', '==', email), where('password', '==', password));
      
      const response = await getDocs(que);
      
      if (response.empty) {
        toast.error('Invalid credentials');
        return;
      }
      
      const userDoc = response.docs[0];
      const userData = userDoc.data();

      // Assuming the user data contains role and permissions information
      const { role, permissions } = userData;
      
      console.log(userData);

      // Set the logged-in user's role and permissions
      setLoggedInUserRole(role);
      setUserPermissions(permissions);
      setUser(userData);

      // Store user data in localStorage
      localStorage.setItem('userRole', role);
      localStorage.setItem('userPermissions', JSON.stringify(permissions));
      localStorage.setItem('user', JSON.stringify(userData));

      // Redirect to respective dashboard based on role
      navigate('/dashboard');
      toast.success('Login successful');
    } catch (error) {
      console.error('Error during login:', error);
      toast.error('Error during login. Please try again.');
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <ToastContainer />
      <div className="card shadow-lg p-5" style={{ maxWidth: '450px' }}>
        <div className="text-center mb-4">
          <img src={logo} alt="Company Logo" className="mb-2" style={{ width: '120px' }} />
          <h2 className="fw-bold">MoneyCart Login</h2>
        </div>

        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <div className="input-group">
              <span className="input-group-text">
                <FaUserAlt />
              </span>
              <input
                id="email"
                type="text"
                className="form-control"
                placeholder="Enter your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="input-group">
              <span className="input-group-text">
                <FaLock />
              </span>
              <input
                id="password"
                type="password"
                className="form-control"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div>
              <input type="checkbox" id="remember-me" className="form-check-input" />
              <label htmlFor="remember-me" className="form-check-label ms-2">
                Remember me
              </label>
            </div>
            <a href="#!" className="text-decoration-none text-primary">
              Forgot Password?
            </a>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-success w-100">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
