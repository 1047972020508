import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { app } from '../../firebase'; // Ensure this path is correct

import NavbarDashboard from '../Dashboard/NavbarDashboard';
import { Box, Select, MenuItem } from '@mui/material';
import ResponsiveDrawer from '../Dashboard/Sidebar';

const firestore = getFirestore(app);

const PaymentStatus = ({ userPermissions }) => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      // Fetch users
      const usersSnapshot = await getDocs(collection(firestore, 'users'));
      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Fetch TDS State data
      const tdsStateSnapshot = await getDocs(collection(firestore, 'tds_state'));
      const tdsStateData = tdsStateSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Fetch TDS Form data
      const tdsFormSnapshot = await getDocs(collection(firestore, 'tds_forms'));
      const tdsFormData = tdsFormSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data()   ,     timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
      }));

      // Fetch TDS Details data
      const tdsDetailsSnapshot = await getDocs(collection(firestore, 'tds_details'));
      const tdsDetailsData = tdsDetailsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Merge data based on userId
      const mergedData = await Promise.all(usersData.map(async (user) => {
        const tdsState = tdsStateData.find(tds => tds.id === user.id) || {};
        const tdsDetails = tdsDetailsData.find(tds => tds.id === user.id) || {};
        const tdsForm = tdsFormData.find(tds => tds.userId === user.id) || {};
        const tdsData2023 = tdsDetails.tdsData2023 || [];
        const tdsData2024 = tdsDetails.tdsData2024 || [];
        const totalTds2023 = tdsData2023.reduce((total, item) => total + Number(item.tds || 0), 0);
        const totalTds2024 = tdsData2024.reduce((total, item) => total + Number(item.tds || 0), 0);
        const totalTds = totalTds2023 + totalTds2024;

        return { 
          ...user, 
          tdsState, 
          ...tdsForm,
          tdsData2023, 
          tdsData2024, 
          totalTds 
        };
      }));

      
      // Sort data by timestamp, latest first
      const sortedData = mergedData.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp) : new Date(0);
        const timestampB = b.timestamp ? new Date(b.timestamp) : new Date(0);
        return timestampB - timestampA;
      });

      setUsers(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

    // Set interval to refresh data every 10 minutes (600,000 milliseconds)
    const intervalId = setInterval(fetchData, 300000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const filteredUsers = users.filter(
    user =>
      (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
          <div className="container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search by username or email..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
              <button onClick={() => {}}>Search</button>
            </div>

            <div className="table-wrapper">
              <div className="table-responsive">
                <table className="responsive-table">
                  <thead className="sticky-header">
                    <tr>
                      <th>Sr No.</th>
                      <th>Date & Time</th>
                      <th>User Name</th>
                      <th>Email ID</th>
                      <th>Phone No.</th>
                      <th>TDS 2023</th>
                      <th>TDS 2024</th>
                      <th>Total TDS</th>
                      <th>Payment Status</th>
                      <th>Payment Confirmation</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={user.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td>{index + 1}</td>
                        <td>{user.timestamp ? new Date(user.timestamp).toLocaleString() : 'NA'}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>{user.tdsData2023.map((tds, idx) => (
                          <div key={idx}>{`Company: ${tds.company}, Income: ${tds.income}, TDS: ${tds.tds}`}</div>
                        ))}</td>
                        <td>{user.tdsData2024.map((tds, idx) => (
                          <div key={idx}>{`Company: ${tds.company}, Income: ${tds.income}, TDS: ${tds.tds}`}</div>
                        ))}</td>
                        <td>{user.totalTds}</td>
                        <td style={{ backgroundColor: user.tdsState.paymentstatus ? '#03C03C' : '#EF0107', color: 'white' }}>
                          {user.paymentstatus ? 'Complete' : 'Incomplete'}
                        </td>
                        <td>
                          <Select
                            style={{ color: 'white', backgroundColor: user.tdsState.paymentConfirmation === 'true' ? '#03C03C' : '#EF0107' }}
                            value={user.tdsState.paymentConfirmation || 'false'}
                            onChange={async (e) => {
                              const updatedUsers = users.map(u => u.id === user.id ? { ...u, tdsState: { ...u.tdsState, paymentConfirmation: e.target.value } } : u);
                              setUsers(updatedUsers);
                              await updateDoc(doc(firestore, 'tds_state', user.id), { paymentConfirmation: e.target.value });
                            }}
                          >
                            <MenuItem value="true">Passed</MenuItem>
                            <MenuItem value="false">Failed</MenuItem>
                          </Select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default PaymentStatus;

// CSS styles for the table
const css = `
/* General styles */
.even {
  background-color: #f2f2f2; /* Light gray background for even rows */
}

.odd {
  background-color: #ffffff; /* White background for odd rows */
}

/* Animation for sliding in table rows */
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.table-row.slide-in-left {
  animation: slide-in-left 0.2s ease forwards;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: lightgreen;
  z-index: 10;
}

.table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-height: calc(100vh - 240px); /* Maximum height for table wrapper */
}

.responsive-table {
  width: 100%; /* Full width for the table */
  border-collapse: collapse; /* Collapse borders */
}

.responsive-table th,
.responsive-table td {
  padding: 12px; /* Padding for table cells */
  text-align: left; /* Align text to the left */
  white-space: wrap; /* Prevent text wrapping */
}

.responsive-table th {
  background-color: lightgreen; /* Light gray background for headers */
  font-weight: bold; /* Bold font for headers */
  border: 1px solid #ddd; /* Border for table headers */
}
`;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);
