import React, { useState, useEffect } from 'react';
import {
  getFirestore,
  doc,
  getDocs,
  getDoc,
  collection,
  updateDoc,
  arrayUnion,
  setDoc
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography
} from '@mui/material';
import NavbarDashboard from '../Dashboard/NavbarDashboard';
import ResponsiveDrawer from '../Dashboard/Sidebar';

// Initialize Firebase services
const firestore = getFirestore();
const storage = getStorage();

const TdsDetails = ({ userPermissions }) => {
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [assessmentYear, setAssessmentYear] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([{ company: '', income: '', tds: '' }]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchData = async () => {
    try {
      const userCollection = await getDocs(collection(firestore, 'users'));
      const tdsStateCollection = await getDocs(collection(firestore, 'tds_state'));
      const tdsDetailsCollection = await getDocs(collection(firestore, 'tds_details'));

          // Fetch TDS Form data
          const tdsFormCollection = await getDocs(collection(firestore, 'tds_forms'));
          const tdsFormData = tdsFormCollection.docs.map(doc => ({ id: doc.id, ...doc.data()   ,     timestamp: doc.data().timestamp?.toDate() // Convert Firestore timestamp to JS Date
          }));
    

      const users = userCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const tdsStates = tdsStateCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const tdsDetails = tdsDetailsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const mergedData = users.map(user => ({
        ...user,
        tdsState: tdsStates.find(tds => tds.id === user.id) || {},
        tdsDetails: tdsDetails.find(tds => tds.id === user.id) || {},
        tdsFormData: tdsFormData.find(tds => tds.id === user.id) || {},
      }));

      
      // Sort data by timestamp, latest first
      const sortedData = mergedData.sort((a, b) => {
        const timestampA = a.tdsFormData.timestamp ? new Date(a.tdsFormData.timestamp) : new Date(0);
        const timestampB = b.tdsFormData.timestamp ? new Date(b.tdsFormData.timestamp) : new Date(0);
        return timestampB - timestampA;
      });

      setUserData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 180000);
    return () => clearInterval(intervalId);
  }, []);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!selectedFile || !selectedUser || !assessmentYear) {
      alert('Please select a file, user, and assessment year.');
      return;
    }

    for (let field of additionalFields) {
      if (!field.company || !field.income || !field.tds) {
        alert('Please fill in all required fields.');
        return;
      }
    }

    const fileName = assessmentYear === '2' ? 'tdsdoc-2023.pdf' : 'tdsdoc-2024.pdf';
    const fileRef = ref(storage, `tdsdocs/${selectedUser.id}/${fileName}`);

    try {
      // Upload file to Firebase Storage
      await uploadBytes(fileRef, selectedFile);
      const downloadURL = await getDownloadURL(fileRef);

      // Update tds_docs collection
      const newDocRef = doc(collection(firestore, `tds_docs/${selectedUser.id}/documents`));
      await setDoc(newDocRef, {
        id: newDocRef.id,
        title: fileName,
        downloadUrl: downloadURL,
        storagePath: fileRef.fullPath
      });

      // Update tds_details collection based on assessment year
      const tdsDetailsDocRef = doc(firestore, 'tds_details', selectedUser.id);
      await setDoc(tdsDetailsDocRef, {
        [assessmentYear === '2' ? 'tdsData2023' : 'tdsData2024']: arrayUnion(...additionalFields)
      }, { merge: true });

      // Update tds_state to reflect tdsA26s as true
      const tdsStateDocRef = doc(firestore, 'tds_state', selectedUser.id);
      await updateDoc(tdsStateDocRef, {
        tdsDetails: true
      });

      alert('Data updated successfully!');
      setDialogOpen(false);
      clearFormData();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again later.');
    }
  };

  const handleAddMoreFields = () => {
    setAdditionalFields([...additionalFields, { company: '', income: '', tds: '' }]);
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...additionalFields];
    newFields[index][field] = value;
    setAdditionalFields(newFields);
  };

  const handleRemoveFields = (index) => {
    if (additionalFields.length > 1) {
      const newFields = [...additionalFields];
      newFields.splice(index, 1);
      setAdditionalFields(newFields);
    }
  };

  const sendNotification = async (user) => {
    try {
      const response = await fetch('https://moneycartadmin.onrender.com/send-notification3', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          message: `Your Form 26 AS has been uploaded successfully. You can now view your TDS refund amount under the ‘Documents’ section`,
        }),
      });

      if (response.ok) {
        console.log('Notification sent successfully!');
      } else {
        console.error('Failed to send notification.');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleStatusChange = async (user, status) => {
    try {
      const tdsStateDocRef = doc(firestore, 'tds_state', user.id);
      await updateDoc(tdsStateDocRef, {
        tdsA26s: status === 'true'
      });

      setUserData(prevData =>
        prevData.map(u => u.id === user.id ? { ...u, tdsState: { ...u.tdsState, tdsA26s: status === 'true' } } : u)
      );

      if (status === 'true') {
        sendNotification(user);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const clearFormData = () => {
    setAssessmentYear('');
    setSelectedFile(null);
    setAdditionalFields([{ company: '', income: '', tds: '' }]);
  };

  const renderTdsFields = () => (
    <>
      {additionalFields.map((field, index) => (
        <div key={index}>
          <TextField
            label="Company"
            variant="outlined"
            fullWidth
            margin="normal"
            value={field.company}
            onChange={(e) => handleFieldChange(index, 'company', e.target.value)}
            required
          />
          <TextField
            label="Income"
            variant="outlined"
            fullWidth
            margin="normal"
            value={field.income}
            onChange={(e) => handleFieldChange(index, 'income', e.target.value)}
            required
          />
          <TextField
            label="TDS"
            variant="outlined"
            fullWidth
            margin="normal"
            value={field.tds}
            onChange={(e) => handleFieldChange(index, 'tds', e.target.value)}
            required
          />
          <Button onClick={() => handleRemoveFields(index)} variant="contained" color="secondary" style={{ marginTop: '10px' }}>
            Remove
          </Button>
        </div>
      ))}
      <Button onClick={handleAddMoreFields} variant="contained" color="primary" style={{ marginTop: '10px' }}>
        Add More
      </Button>
    </>
  );

  const handleRowClick = (user) => {
    setSelectedUser(user);
    clearFormData();  // Clear form data when a new user is selected
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const filteredUsers = userData.filter(
    user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <NavbarDashboard />
      <Box height={10} />
      <Box sx={{ display: 'flex' }}>
        <ResponsiveDrawer userPermissions={userPermissions} />
        <Box component="main" sx={{ flexGrow: 1, p: 9 }}>
          <div className="container">
            <div className="card p-4">
              <Typography variant="h4" gutterBottom component="div">
                TDS Details
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <TextField
                  label="Search by Name or Email"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ marginBottom: '20px', width: '300px' }}
                />
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className="sticky-header">
                    <TableRow >
                      <TableCell>Date & Time </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>26As Status</TableCell>
                      <TableCell>Upload Details</TableCell>
                      <TableCell>26As Form Upload</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.tdsFormData.timestamp ? new Date(user.tdsFormData.timestamp).toLocaleString() : 'NA'}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell className={user.tdsState.fileTds ? 'Active' : 'Inactive'}>{user.tdsState.fileTds ? 'Active' : 'Inactive'}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRowClick(user)}
                          >
                            Add TDS Details
                          </Button>
                        </TableCell>
                        <TableCell className={user.tdsState.tdsA26s ? 'Uploaded' : 'Incomplete'}>
                        <Select
                          style={{ color: 'white' }}
                          value={user.tdsState.tdsA26s ? 'true' : 'false'}
                          
                          onChange={(e) => handleStatusChange(user, e.target.value)}
                        >
                          <MenuItem value="true">Uploaded</MenuItem>
                          <MenuItem value="false">Incomplete</MenuItem>
                        </Select>
                      </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Add TDS Details</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="assessment-year-label">Assessment Year</InputLabel>
                    <Select
                      labelId="assessment-year-label"
                      value={assessmentYear}
                      onChange={(e) => setAssessmentYear(e.target.value)}
                      required
                    >
                      <MenuItem value="2">2023-2024</MenuItem>
                      <MenuItem value="3">2024-2025</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    type="file"
                    onChange={handleFileChange}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  {renderTdsFields()}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default TdsDetails;
const css = `
  .Incomplete, .Inactive {
    background-color: #EF0107;
    color: white;
    border-style: solid;
  }
    .Uploaded , .Active{
    background-color: #03C03C;
    color: white;
    border-style: solid;
  }

    .sticky-header {
    position: sticky;
    top: 0;
    background-color: lightgreen;
    z-index: 10;
  }

    `;

document.head.insertAdjacentHTML('beforeend', `<style>${css}</style>`);